import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <div className="icon-com">
              <img alt="" src="/head/foot1.svg" />
              <p>
                Point North, Park Plaza,
                <br />
                WS12 2DB, United Kingdom
              </p>
              <div className="PcFun">
                <img alt="" src="/head/foot2.png" />
                <div className="gGHpXm">
                  <h4>#FATJOESWAG</h4>
                  <p>
                    Supporting
                    <strong style={{ color: "rgb(195, 255, 0)" }}>
                      Children’s Cancer Research Fund
                    </strong>
                  </p>
                  <Link to="/" onClick={toTop}>
                    <button>Visit our SWAG</button>
                  </Link>
                </div>
              </div>
            </div>
            <ul>
              <h3 className="comImfoH3">Company</h3>
              <li>
                <Link to="/" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={toTop}>
                  About Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="hFhRqN">
          <div className="hFhRqN-inner">
            <div className="hfh-left">
              <p>Copyright © 2024 FATJOE. All rights reserved.</p>
            </div>
            <div className="hfh-mid">
              <img src="/head/foot3.png" alt="video-call" />
            </div>
            <div className="hfh-right">
              <Link to="/terms" onClick={toTop}>
                Terms Of Service
              </Link>
              <Link to="/privacy" onClick={toTop}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
