import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <div className="jDxKwq">
            <div className="jdk_left">
              <h1>
                All your <span className="seo-span">SEO</span> deliverables in
                one platform.
              </h1>
              <p className="gzpXxE">
                <span className="orders">203,636</span> orders delivered since
                2012
              </p>
              <p className="gzpXxE1">
                <img alt="" src="/head/check.svg" />
                White label SEO services with superfast turnarounds
              </p>
              <p className="gzpXxE1">
                <img alt="" src="/head/check.svg" />
                Unrivalled quality for SEO agencies and teams
              </p>
              <p className="gzpXxE1">
                <img alt="" src="/head/check.svg" />
                ROI & margins designed for SEO resellers
              </p>
              <div className="crEQYa">
                <p>
                  We are rated<span style={{ fontWeight: "600" }}>4.8</span>
                  <img alt="" src="/head/shop.svg" />
                  based on
                  <span style={{ fontWeight: "600" }}> 1362 reviews</span>
                </p>
              </div>

              <Link className="a-to-dr">
                <button className="explore-btn">Explore Our Services</button>
                <p className="drLJPK">
                  723 SEO agencies
                  <br /> signed up in the last 30 days
                </p>
              </Link>
            </div>
            <div className="jdk_right">
              <img alt="" src="/head/main-right.png" />
            </div>
          </div>
        </div>
        <div className="jwAFXQ">
          <div className="jwa-inner">
            <img alt="" src="/head/main1.png" />
            <img alt="" src="/head/main2.png" />
            <img alt="" src="/head/main3.png" />
            <img alt="" src="/head/main4.png" />
            <img alt="" src="/head/main5.png" />
            <img alt="" src="/head/main6.png" />
          </div>
        </div>
        <div className="mainBox1">
          <div className="container-fluid">
            <div className="top-container">
              <h1>Explore the marketplace.</h1>
              <div className="product-grid">
                {productData.map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link
                        to={`/shopDetails/${brand.id}`}
                        onClick={handleNavigate}
                      >
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <div className="pp-pre">
                        <h2>{brand.name}</h2>
                        <p>Starting from £{brand.price}</p>
                        <p className="days-p">
                          <img
                            src="https://img.icons8.com/sf-regular-filled/48/FFFFFF/flash-on.png"
                            alt="flash-on"
                            className="lighting"
                          />
                          <div className="days-div">{brand.days}&nbsp;days</div>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="jsVideo">
              <div className="js-inner">
                <div className="inner__left">
                  <p>We have the answer.</p>
                  <h2 style={{ display: "flex" }}>
                    In-house vs freelancers? Neither.
                    <img
                      alt=""
                      src="/head/line.png"
                      style={{ width: "116px", height: "70px" }}
                    />
                  </h2>
                </div>
                <div className="inner__right">
                  <div>
                    <img alt="" src="/head/np.png" />
                    <p>"Top 5 Link Building Agency" - NP Digital</p>
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    <img alt="" src="/head/g.png" />
                    <p style={{ marginLeft: "28px" }}>
                      "Best Blogger Outreach Service" - Gotch SEO
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="acea7f7a">
              <div className="ace-inner">
                <div className="fXNcsU ">
                  <img alt="" src="/head/ace1.svg" />
                </div>
                <h3>White Label Services</h3>
                <p>White label services designed for agencies to resell</p>
              </div>
              <div className="ace-inner">
                <div className="fXNcsU ">
                  <img alt="" src="/head/ace2.svg" />
                </div>
                <h3>Fanatical Support</h3>
                <p>A dedicated support team on hand via telephone & email</p>
              </div>
              <div className="ace-inner">
                <div className="fXNcsU ">
                  <img alt="" src="/head/ace3.svg" />
                </div>
                <h3>No Vendor Clutter</h3>
                <p>No need to manage multiple freelancers & vendors</p>
              </div>
              <div className="ace-inner">
                <div className="fXNcsU ">
                  <img alt="" src="/head/ace4.svg" />
                </div>
                <h3>Money Back Guarantee</h3>
                <p>100% money back guarantee on all of our services</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cEPAau">
          <div className="bxqgeh">
            <h2>
              A workspace designed for outsourcing efficiency
              <img alt="" src="/head/line1.png" />
            </h2>
            <p>Invite team members to place, track & manage orders with you.</p>
            <div className="kkanry ">
              <img
                src="/head/DashboardGraphic.png"
                alt="orders"
                style={{ width: "530px", height: "412px", marginRight: "4%" }}
              />
              <div className="widget-father">
                <div className="widget">
                  <img alt="" src="/head/check.svg" />
                  <h3>Add Your Team</h3>
                  <p>Easily add multiple users to place and manage orders.</p>
                </div>
                <div className="widget">
                  <img alt="" src="/head/check.svg" />
                  <h3>Easy Ordering</h3>
                  <p>
                    Place orders easily, including one-click repeat ordering.
                  </p>
                </div>
                <div className="widget">
                  <img alt="" src="/head/check.svg" />
                  <h3>White Label</h3>
                  <p>All services are white label for easy reselling.</p>
                </div>
                <div className="widget">
                  <img alt="" src="/head/check.svg" />
                  <h3>Scalable Prices</h3>
                  <p>
                    We offer reliable low prices that are designed with
                    resellers in mind.
                  </p>
                </div>
              </div>
            </div>
            <Link to="/">
              <button>Explore Our Services</button>
            </Link>
          </div>
        </div>
        <div className="mainBox2">
          <div className="fnyMYC">
            <h2>Here to learn about SEO & marketing?</h2>
            <p>
              We’ll teach you about SEO & marketing with our video and blog
              training guides
            </p>
            <div className="fny-inner">
              <div className="inner-grid">
                <div className="grid__img">
                  <img alt="" src="/head/seo1.png" />
                </div>
                <div className="grid__txt">
                  <h6>---&nbsp;Agency Advice&nbsp;---</h6>
                  <h4>Is SEO Worth It In 2024? These Stats Prove It Is!</h4>
                </div>
              </div>
              <div className="inner-grid">
                <div className="grid__img">
                  <img alt="" src="/head/seo2.png" />
                </div>
                <div className="grid__txt">
                  <h6>---&nbsp;Agency Advice&nbsp;---</h6>
                  <h4>40+ Ways To Get SEO Leads & Win Clients in 2024</h4>
                </div>
              </div>
              <div className="inner-grid">
                <div className="grid__img">
                  <img alt="" src="/head/seo3.png" />
                </div>
                <div className="grid__txt">
                  <h6>---&nbsp;SEO&nbsp;---</h6>
                  <h4>What Is SEO? A Guide To Learn SEO For Beginners</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="ftzgRm">
            <div className="ftzgRm-inner">
              <h2>What our customers think.</h2>
              <p>
                Read feedback from Agencies, In-House Marketing Teams &
                Individuals just like you!
              </p>
              <div className="dJwNtM">
                <div className="dJwNtM-left">
                  <h3 className="iHQEFY">95%</h3>
                  <p>
                    of customers that buy from this merchant give them a 4 or
                    5-star rating
                  </p>
                </div>
                <div className="dJwNtM-mid">
                  <img
                    alt=""
                    src="/head/line3.png"
                    style={{ width: "164px", height: "85px" }}
                  />
                  <div className="hrteGS">
                    <p>Overall Rating</p>
                    <p style={{ display: "flex" }}>
                      <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/sf-black-filled/50/star.png"
                        alt="star"
                      />
                      <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/sf-black-filled/50/star.png"
                        alt="star"
                      />
                      <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/sf-black-filled/50/star.png"
                        alt="star"
                      />
                      <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/sf-black-filled/50/star.png"
                        alt="star"
                      />
                      <img
                        width="50"
                        height="50"
                        src="https://img.icons8.com/sf-black-filled/50/star.png"
                        alt="star"
                      />
                    </p>
                  </div>
                  <img
                    alt=""
                    src="/head/line4.png"
                    style={{ width: "209px", height: "113px" }}
                  />
                </div>
                <div className="dJwNtM-right">
                  <h3 className="iHQEFY">4.8/5</h3>
                  <div width="84" className="sc-258f4144-0 kjobsF"></div>
                  <div width="10" className="sc-258f4144-0 jMMRCB"></div>
                  <div width="4" className="sc-258f4144-0 gSILFn"></div>
                  <div width="1" className="sc-258f4144-0 iixLjj"></div>
                  <div width="1" className="sc-258f4144-0 hJzfzk"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="hQehhm ">
            <div className="hQehhm-inner">
              <div className="jfpmzQ ">
                <h4>Excellent</h4>
                <p style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                </p>
                <p className="reviews">Based on 1362 reviews</p>
                <img alt="" src="/head/star.svg" />
              </div>
              <div className="jfpmzQ1">
                <p style={{ display: "flex", height: "20px" }}>
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <p className="days">3 days ago</p>
                </p>
                <p className="reviews1">Good</p>
                <hr className="line-hr" />
                <p className="gFPylo">Glyn Lewis</p>
              </div>
              <div className="jfpmzQ1">
                <p style={{ display: "flex", height: "20px" }}>
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <p className="days">45 days ago</p>
                </p>
                <p className="reviews1">Very easy and clear steps.</p>
                <hr className="line-hr" />
                <p className="gFPylo">YUBAN TABORDA</p>
              </div>
              <div className="jfpmzQ1">
                <p style={{ display: "flex", height: "20px" }}>
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <img
                    width="20"
                    height="20"
                    src="https://img.icons8.com/sf-black-filled/50/FD7E14/star.png"
                    alt="star"
                  />
                  <p className="days">23 days ago</p>
                </p>
                <p className="reviews1">
                  Ordering is always easy and the Pro content is always great. I
                  am expanding my busine...
                </p>
                <hr className="line-hr" />
                <p className="gFPylo">Tom Sawyer</p>
              </div>
            </div>
          </div>

          <div className="cZVIut">
            <div className="cZVIut-inner">
              <div className="cZVIut-left">
                <h2>The no hassle way to get SEO done.</h2>
                <div className="itghAB">
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      2012
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Founded In
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      40k+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Agency Accounts
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      20+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Services Offered
                    </p>
                  </div>
                </div>
                <Link>
                  <button>Explore Our Services</button>
                </Link>
              </div>
              <div className="cZVIut-right">
                <img alt="" src="/head/cry.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
