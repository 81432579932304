import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="headerDiv">
        <div className="leftImg">
          <img alt="" src="/head/head2.svg" className="head2-svg" />
          <img alt="" src="/head/head1.png" className="head1-png" />
          <div className="alert">Grow - managed links & content!</div>
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA" onClick={toTop}>
                  HOME
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/privacy" className="homeToA" onClick={toTop}>
                  Privacy
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/about" onClick={toTop}>
                  About
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="rightT">
          <p className="cartItemCount">{cartItems.length}</p>
          <Link to="/cartpage" className="to-add" onClick={toTop}>
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/metro/26/FFFFFF/shopping-cart.png"
              alt="shopping-cart"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
