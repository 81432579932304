import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Terms of service</h1>
            <p>The small print...</p>
          </div>
          <h4>Introduction</h4>
          <p>
            FATJOE will provide various digital services as described on the
            website. All fees must be paid in full prior to the start of the
            campaign.
          </p>
          <h5>FATJOE Services will include (but are not limited to):</h5>
          <p>
            <ul>
              <li>
                Obtaining “backlinks” from other websites, blogs and directories
                in order to generate link popularity.
              </li>
              <li>Creating content/visual documents</li>
              <li>Creating placement and submission reports</li>
            </ul>
          </p>
          <h5>
            Clients must acknowledge the following with respect to the services:
          </h5>
          <p>
            <ul>
              <li>All fees are non-refundable.</li>
              <li>
                Changes to completed orders and projects within orders, such as
                amendments to anchor texts and target URLs, may incur an admin
                fee of £15 per change, subject to the discretion of the FATJOE
                Support team. Other changes to completed projects may incur
                other charges.
              </li>
              <li>
                All fees, services, documents, recommendations and reports are
                confidential.
              </li>
              <li>
                FATJOE has no control over the policies of search engines with
                respect to the type of sites and/or content that they accept now
                or in the future. The Client’s website may be excluded from any
                blog, website, directory or search engine at any time at the
                sole discretion of the search engine or directory.
              </li>
              <li>
                FATJOE has no control over website and blog owners removing
                links and content, or deleting/moving/changing their website.
                Refunds and replacements will only be processed per the refund
                policies of each individual service.
              </li>
              <li>
                Under no circumstances shall FATJOE be liable to you (the end
                user) for any damages whatsoever, including (without limitation)
                any direct incidental or consequential damages, loss of profits,
                or any claims of yours or third party websites. You, the end
                user assume all risks and potential damages (known or unknown)
                associated with using FATJOE services.
              </li>
              <li>
                FATJOE reserves the right to amend anchor text and other order
                details to ensure successful placements or completion of
                services.
              </li>
              <li>
                As an outsourced provider of specific SEO services, FATJOE does
                not guarantee search engine positions for any particular
                keyword, phrase, or search term. Neither does FATJOE guarantee
                any traffic increases.
              </li>
              <li>
                Any form of link building inherently incurs risks of search
                engine penalties and other damage to SEO efforts. FATJOE does
                not assume liability for any impacts of link building and other
                SEO activities upon the Client’s website rankings.
              </li>
              <li>
                FATJOE is not responsible for changes made to the website by
                other parties that adversely affect the search engine rankings
                of the Client’s website.
              </li>
            </ul>
          </p>
          <h5>Non Disclosure</h5>
          <p>
            FATJOE does not sign individual NDAs for every agency or client that
            wishes to use our services; however, as part of the terms of our
            service, you can be sure that we treat every client with full
            Non-Disclosure respect.
          </p>
          <p>
            The Recipient (FATJOE) undertakes not to use the Confidential
            Information disclosed by the other party (You) for any purpose
            except the Purpose without first obtaining the written agreement of
            the other party.
          </p>
          <p>
            We guarantee not to disclose any confidential information to any
            third party except to your company’s employees [and professional
            advisers] who need to know the same for the purpose.
          </p>
          <h5>Restrictions</h5>
          <p>
            FATJOE reserves the right to cancel any orders placed for websites
            in illegal or offensive industries. Any illegal content, products,
            services or items hateful, objectionable or defamatory content aimed
            at any people group, race, gender, sexual orientation or otherwise
            attacks on any person, business, organisation, product or service;
            cruelty towards animals; or otherwise content found to be
            objectionable will be cancelled and refunded.
          </p>
          <p>
            Prescription drugs, gambling or casinos, and adult orders MAY be
            possible but have to be treated separately and will be cancelled and
            refunded if ordered through the typical online order forms.
          </p>
          <p>
            If you are unsure if your site violates our restrictions, please
            contact us FIRST.
          </p>
          <h5>Emails</h5>
          <p>
            By joining, you agree to receive emails from FATJOE, which could be
            in the form of order notifications, system updates, new services,
            offers or educational material.
          </p>
          <p>
            All Parties irrevocably agree that the courts of England and Wales
            have the exclusive jurisdiction to:
            <br />
            (a) determine any dispute;
            <br />
            (b) grant interim remedies;
            <br />
            (c) grant any other provisional or protective relief.
          </p>
          <h5>Subscriptions – Subscribe & Save</h5>
          <p>
            You may cancel your Subscribe & Save credit subscription at any
            time.
          </p>
          <p>
            No refunds can be provided for unused credits added via your
            subscription.
          </p>
          <p>
            Unused credits will remain available in your account after your
            subscription is canceled.
          </p>
          <p>
            Subscribe & Save is designed to be subscribed to long-term, not just
            to activate a discount. While Subscribe & Save is ‘cancel anytime,’
            our system monitors for short activation periods of 14 days or less,
            and discounted orders will be canceled from users suspected of
            misusing the system.
          </p>
          <div className="cZVIut">
            <div className="cZVIut-inner">
              <div className="cZVIut-left">
                <h2>The no hassle way to get SEO done.</h2>
                <div className="itghAB">
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      2012
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Founded In
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      40k+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Agency Accounts
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      20+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Services Offered
                    </p>
                  </div>
                </div>
                <Link to="/">
                  <button>Explore Our Services</button>
                </Link>
              </div>
              <div className="cZVIut-right">
                <img alt="" src="/head/cry.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Terms;
