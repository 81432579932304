import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(item.startPrice);
        total += price * item.quantity;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, itemDetails, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const item = cartItems.find(
      (cartItem) => cartItem.id === itemId && cartItem.details === itemDetails
    );

    if (!item) return 0;
    const price = parseFloat(item.startPrice);
    return price * quantity || price * 1;
  };

  const handleInputChange = (id, details, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, details, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>Shopping Basket</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ borderTopLeftRadius: "12px" }}>
                          Product
                        </TableCell>
                        <TableCell align="left">Unit Price</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="left">Net Subtotal (£)</TableCell>
                        <TableCell
                          align="center"
                          style={{ borderTopRightRadius: "12px" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems
                        .filter(
                          (product) => product.id >= 1 && product.id <= 10
                        )
                        .map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{item.details}</TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {item.startPrice}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <input
                                  type="number"
                                  value={item.quantity || 1}
                                  className="cartInput"
                                  onChange={(e) =>
                                    handleInputChange(item.id, item.details, e)
                                  }
                                  min="0"
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ verticalAlign: "middle" }}
                              >
                                £
                                {calculateItemTotal(
                                  item.id,
                                  item.details,
                                  item.quantity
                                ).toFixed(2)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "2px solid rgb(243, 243, 243)",
                                }}
                              >
                                <a
                                  href="#"
                                  onClick={() =>
                                    removeFromCart(item.id, item.details)
                                  }
                                  style={{
                                    cursor: "context-menu",
                                    textDecoration: "underline",
                                    fontFamily: "Libre Baskerville",
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table>
                      <tbody>
                        <tr>
                          <td>Net Total</td>
                          <td> £{calculateTotal().toFixed(2)}</td>
                        </tr>
                        <tr class="express">
                          <td>
                            Express Delivery <small>(14 days)</small>
                          </td>
                          <td>£17.50</td>
                        </tr>
                        <tr class="total">
                          <td>
                            GBP Total <br />
                            <small>Approx USD Total</small>
                          </td>
                          <td>
                            £{(calculateTotal() + 17.5).toFixed(2)}
                            <br />
                            <small>
                              ${((calculateTotal() + 17.5) * 1.3).toFixed(2)}
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="checkout-button" onClick={toCheckOut}>
                      Pay by Card
                    </button>
                    <div className="cart-grid">
                      <img alt="" src="/head/cart1.svg" />
                      <img alt="" src="/head/cart2.svg" />
                      <img alt="" src="/head/cart3.svg" />
                      <img alt="" src="/head/cart4.svg" />
                      <img alt="" src="/head/cart5.svg" />
                      <img alt="" src="/head/cart6.svg" />
                      <img alt="" src="/head/cart7.svg" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
