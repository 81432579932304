import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Our Privacy & Cookie Policy.</h1>
            <p>The Boring Stuff, simplified!</p>
          </div>
          <div className="hQehhm1">
            <div className="hQehhm-inner">
              <div className="jRrleS">
                <div className="counter">1</div>
                <h3 className="elUBNT">Improve Experiences</h3>
                <p className="xCzNI">
                  We only ever use your data to improve your user experience
                  with us.
                </p>
              </div>
              <div className="jRrleS">
                <div className="counter">2</div>
                <h3 className="elUBNT">No Nonsense</h3>
                <p className="xCzNI">
                  We’ve simplified our privacy policy with no technical jargon.
                </p>
              </div>
              <div className="jRrleS">
                <div className="counter">3</div>
                <h3 className="elUBNT">Zero Spam</h3>
                <p className="xCzNI">
                  You can completely control what and how you hear from us.
                </p>
              </div>
            </div>
          </div>
          <p>
            Here at FATJOE, we are committed to protecting the data we may
            collect from visitors on our website and users of our products and
            services. It’s important for us that we maintain your trust and
            confidence and we just want to let you know that we do not sell,
            rent or trade any of your data with any other third party for any
            purpose. To show our commitment we’ll outline in this policy exactly
            when and why we may collect your personal information, how we keep
            it safe and how we may use it under limited conditions.
          </p>
          <div className="jpBCOT">
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>How we use your information</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  If you hold an account or use any FATJOE products, then you’ll
                  know that we collect basic contact information such as name,
                  company name, email address, company address, and VAT number,
                  if applicable. We use this to:
                </p>
                <p>
                  Deliver notifications and updates on your purchases or our
                  tools
                  <br />
                  Deliver targetted FATJOE adverts as you browse the web
                  <br />
                  Send information on new products and services (only with your
                  permission)
                </p>
                <p>
                  We also collect information covered in more detail in our ‘Use
                  of Cookies’ when you browse our website, apps, and tools, such
                  as your IP address, device type, location data (if you choose
                  to share this), and standard internet log data on how you use
                  our website or tools. This information is used to improve our
                  website user experience and deliver optimized targeted ads.
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Use of Cookies</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  Our website uses cookies to collect information to help us
                  enhance and deliver a better user experience. Cookies are
                  small data files that are stored on your computer or other
                  devices (such as smartphones or ‘tablets’) as you browse our
                  website.
                </p>
                <p>
                  Cookies collect information about browsing and purchasing
                  behavior via the same computer or device, which includes
                  information about pages viewed, time spent on a web page,
                  products purchased, and your journey around a website. We use
                  these cookies to deliver targeted ads relevant to you, measure
                  the effectiveness of marketing campaigns, and even limit the
                  number of times you may see an ad.
                </p>
                <p>
                  Don’t worry, the cookies we collect do not record personal
                  information like your name, address, or any other contact
                  details, nor can we take over your computer and hack your
                  Facebook account.
                </p>
                <p>
                  By using our site, you agree to us placing these types of
                  cookies on your computer or device and accessing them when you
                  visit the site in the future. You can delete cookies anytime
                  from the settings section of your website browser. Further
                  information about cookies can be found at
                  https://ico.org.uk/for-organisations/guide-to-pecr/cookies-and-similar-technologies/
                  .
                </p>
                <p>
                  Please note that if you disable cookies, your user experience
                  may be affected when using our website.
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Sharing your information</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  We do not sell, rent, or trade any of your data with any other
                  third party; however, we share your data with the following
                  categories of companies as an essential part of being able to
                  provide our services to you:
                </p>
                <p>
                  <ul>
                    <li>
                      Payment Service Providers, such as PayPal who handle all
                      payment transactions
                    </li>
                    <li>
                      Professional service providers such as advertising
                      partners, marketing agencies, and website hosts
                    </li>
                    <li>
                      Law enforcement and fraud protection agencies so we can
                      help tackle fraud
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Marketing messages and emails</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  If you’ve given us permission to, we’ll contact you from time
                  to time to deliver you important updates, offers, and news
                  about our company, products, and services. You’ll be able to
                  opt-out at any time by clicking the ‘unsubscribe’ link in any
                  email or by contacting our support team at reply@esgloa.shop
                </p>
                <p>
                  Please note if you hold an account with FATJOE, you will
                  receive notifications regarding your account or purchase
                  updates in order for us to deliver our contract with you. If
                  you no longer use your account and would like to delete it,
                  please contact us by email to reply@esgloa.shop
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>How we protect your information</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  We adopt appropriate data collection, storage, and processing
                  practices and security measures to protect against
                  unauthorized access, alteration, disclosure, or destruction of
                  your personal information, username, password, transaction
                  information, and data stored on our site.
                </p>
                <p>
                  Sensitive and private data exchange between the Site and its
                  Users happens over an SSL-secured communication channel and is
                  encrypted and protected with digital signatures.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Third party websites</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  Users may find advertising or other content on our website
                  that links to the sites and services of our partners,
                  suppliers, advertisers, sponsors, licensors, and other third
                  parties. We do not control the content or links that appear on
                  these sites and are not responsible for the practices employed
                  by websites linked to or from our website. In addition, these
                  sites or services, including their content and links, may be
                  constantly changing. These sites and services may have their
                  own privacy policies and customer service policies. Browsing
                  and interaction on any other website, including websites that
                  have a link to our website, is subject to that website’s own
                  terms and policies.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Your rights</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  You have the right to access, amend, and delete any personal
                  data that we hold about you. Here’s how you can do this.
                </p>
                <p>
                  <ul>
                    <li>
                      <strong>Correcting inaccurate information:</strong>If you
                      hold an account with FATJOE, then you can do most of this
                      on your dashboard. Just go to My Profile.
                    </li>
                    <li>
                      <strong>Accessing personal data:</strong>You can obtain
                      this by emailing one of our support team members with your
                      request to reply@esgloa.shop
                    </li>
                    <li>
                      <strong>Stop marketing messages: </strong>You can do this
                      anytime by clicking the ‘unsubscribe’ link in any email or
                      by contacting our support team at reply@esgloa.shop
                    </li>
                    <li>
                      <strong>Deleting personal data:</strong>We can delete your
                      data and stop processing it should you wish. Simply
                      contact our support team at reply@esgloa.shop
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Changes to our privacy policy</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  We may change this page from time to time to reflect how we
                  are collecting and processing your data. When we do, we will
                  revise the updated date at the bottom of this page. We
                  encourage users to frequently check this page for any changes
                  to stay informed about how we are helping to protect the
                  personal information we collect. You acknowledge and agree
                  that it is your responsibility to review this privacy policy
                  periodically and become aware of modifications however, if we
                  make significant changes, we will make that clear to you
                  either on our website or other means such as email.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>How to contact us</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  If you have any questions about this page, our products,
                  services, website or anything else, please don’t hesitate to
                  contact our support team below.
                </p>
                <p>
                  (01543) 496846
                  <br />
                  (949) 281 1106
                  <br />
                  reply@esgloa.shop
                </p>
                <p>You can also write to us at:</p>
                <p>
                  FATJOE
                  <br />
                  Point North, Park Plaza
                  <br />
                  Staffordshire, WS12 2DB
                  <br />
                  United Kingdom
                </p>
              </div>
            </div>
          </div>
          <p className="gyujNp">Last Updated: September 14, 2023</p>
          <div className="cZVIut">
            <div className="cZVIut-inner">
              <div className="cZVIut-left">
                <h2>The no hassle way to get SEO done.</h2>
                <div className="itghAB">
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      2012
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Founded In
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      40k+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Agency Accounts
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      20+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Services Offered
                    </p>
                  </div>
                </div>
                <Link to="/">
                  <button>Explore Our Services</button>
                </Link>
              </div>
              <div className="cZVIut-right">
                <img alt="" src="/head/cry.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
