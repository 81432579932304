import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="term-h3">
        <div className="terms-left">
          <h1>Building the ultimate white label SEO platform.</h1>
          <p>
            FATJOE was founded in 2012 and has become one of the world’s largest
            providers of outsourced
            <Link to="/">Link Building</Link>,<Link to="/">Digital PR,</Link>
            <Link to="/">SEO Services</Link>,
            <Link to="/">Content Creation</Link> and
            <Link to="/">Design and Video</Link> services. We’re changing the
            game with our productized link building and SEO services, helping
            SEOs, marketers, and agencies scale and thrive.
          </p>
        </div>
        <div className="terms-right">
          <img alt="" src="/head/about.webp" />
        </div>
      </div>

      <div className="team-img">
        <p style={{ fontSize: "20px", marginBottom: "-2px" }}>The team.</p>
        <h3 className="question-h3">Meet our team of experts.</h3>
        <p>Our ever-growing team of experts are here to help.</p>
        <p>
          From our dedicated support team to our amazing product leads, the
          whole team
        </p>
        <p>
          is on hand to help you provide the greatest marketing and SEO services
          possible.
        </p>
        <img alt="" src="/head/team.jpg" />
      </div>

      <div className="value-div">
        <div className="value-inner">
          <div className="value--left">
            <p>Our values.</p>
            <h2>Not your average company. </h2>
          </div>
          <div className="value--right">
            <div className="bqOuRB ">
              <div className="advice">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/ios/50/gender-neutral-user--v1.png"
                  alt="gender-neutral-user--v1"
                />
              </div>
              <h3>Friendly Atmosphere</h3>
              <p>
                We pride ourselves on our relaxed and friendly atmosphere with
                regular office treats (and office dogs!).
                <br />
                We encourage team members to grow, learn, and take on new
                challenges.
              </p>
            </div>
            <div className="bqOuRB ">
              <div className="advice">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/metro/50/star.png"
                  alt="star"
                />
              </div>
              <h3>Our Mission</h3>
              <p>
                We’re on a mission to provide the best possible outsourced
                services for marketing and SEO professionals.
                <br />
                We want to help you scale your services with our completely
                white-label products.
              </p>
            </div>
            <div className="bqOuRB ">
              <div className="advice">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/material-outlined/50/key-security.png"
                  alt="key-security"
                />
              </div>
              <h3>Charity Friendly</h3>
              <p>
                We believe in good causes and giving back! Every month, a member
                of our team gets to nominate a charity that we’ll donate to.
                <br />
                We’re also always looking to get involved in local causes to
                help out.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <h3 className="question-h3">
            Every month we donate a percentage of company profits to a{" "}
            <div className="line-div">
              <img
                width="50"
                height="50"
                src="https://img.icons8.com/ios-glyphs/50/wavy-line.png"
                alt="wavy-line"
              />
            </div>
            charity chosen by one of our team.
          </h3>
          <p className="center-p">
            As well as monthly donations, we donate profits of our Swag to
            Cancer Research and our team regularly takes part in various
            charitable events.
          </p>
          <img
            alt=""
            src="/head/charity.png"
            style={{ marginTop: "2%", marginBottom: "2%" }}
          />
          <div className="litter">
            <div className="litter-left">
              <h3>Alone we can do so little: together we can do so much.</h3>
              <p>- Helen Keller</p>
            </div>
            <div className="litter-right"></div>
          </div>
          <div className="litter1">
            <div className="litter1-left">
              <h6>SEO T-Shirts, Hoodies, iPhone Cases, Mugs & Gifts.</h6>
              <h2>Supporting Children's Cancer Research.</h2>
              <p>
                All SEO products are sold and fulfilled by Teespring with US and
                International Shipping. All purchases will be printed and
                despatched on a campaign basis with an optional expedited rush
                option. This means that once you have placed an order, your
                products will be printed and despatched once the time has ended
                on the product (usually every 5 days).
              </p>
              <Link to="/">
                <button className="cJYaS">Shop #FATJOESWAG</button>
              </Link>
            </div>
            <div className="litter1-right">
              <img class="image-0" src="/head/swag-1.jpeg" alt="" />
              <img class="image-1" src="/head/swag-3.jpeg" alt="" />
              <img class="image-2" src="/head/sawg-2.jpeg" alt="" />
            </div>
          </div>

          <div className="cZVIut">
            <div className="cZVIut-inner">
              <div className="cZVIut-left">
                <h2>The no hassle way to get SEO done.</h2>
                <div className="itghAB">
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      2012
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Founded In
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      40k+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Agency Accounts
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      20+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Services Offered
                    </p>
                  </div>
                </div>
                <Link to="/">
                  <button>Explore Our Services</button>
                </Link>
              </div>
              <div className="cZVIut-right">
                <img alt="" src="/head/cry.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
