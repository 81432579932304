import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (productId, details, quantity, startPrice) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item.id === productId && item.details === details
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId && item.details === details
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          details,
          quantity,
          startPrice,
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (productId, details) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) => item.id !== productId || item.details !== details
      )
    );
  };

  const updateQuantity = (productId, details, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId && item.details === details
          ? { ...item, quantity }
          : item
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
