import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(
    Array(product.details.length).fill(1)
  );
  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (details, startPrice, index) => {
    addToCart(product.id, details, quantities[index], startPrice);
    navigate("/cartpage");
  };

  const handleQuantityChange = (index, delta) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max(1, newQuantities[index] + delta);
      return newQuantities;
    });
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-info">
            <div className="jTfReu">
              <div className="kGOoHL">
                <div className="upeDw-left">
                  <div className="cOTrvs">
                    <div className="cmFJRM">
                      <img
                        width="15"
                        height="15"
                        src="https://img.icons8.com/material-outlined/50/FFFFFF/checkmark.png"
                        alt="checkmark"
                      />
                    </div>
                    <p>{product.name}</p>
                  </div>
                  <div className="cOTrvs">
                    <div className="cmFJRM">2</div>
                    <p>Order Details</p>
                  </div>
                  <div className="cOTrvs">
                    <div className="cmFJRM1">3</div>
                    <p style={{ color: "#fff" }}>Summary</p>
                  </div>
                </div>
                <div className="upeDw-right">
                  <p>GBP £60.00 | USD $80.00</p>
                </div>
              </div>
            </div>
            <Link to="/" className="link-to-item">
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/material-outlined/50/1A1A1A/long-arrow-left.png"
                alt="long-arrow-left"
              />
              Back to {product.name}
            </Link>
            <div className="jsw-bqo">
              <div className="jwSmyw ">
                <h2>{product.main}</h2>
                {product.details.map((details, index) => (
                  <div className="price-section" key={index}>
                    <div className="weightDiv">
                      <h3>{details}</h3>
                      <div className="takeQuantity">
                        <button
                          className="quantity-button"
                          onClick={() => handleQuantityChange(index, -1)}
                          style={{ marginLeft: "12.6px", marginTop: "8px" }}
                        >
                          <img
                            width="34"
                            height="12"
                            src="https://img.icons8.com/ios-glyphs/50/FFFFFF/minus-math.png"
                            alt="minus-math"
                          />
                        </button>
                        <input
                          type="text"
                          value={quantities[index]}
                          onChange={(e) =>
                            setQuantities((prevQuantities) => {
                              const newQuantities = [...prevQuantities];
                              newQuantities[index] = Math.max(
                                1,
                                parseInt(e.target.value)
                              );
                              return newQuantities;
                            })
                          }
                          className="quantity-input"
                        />
                        <button
                          className="quantity-button"
                          onClick={() => handleQuantityChange(index, 1)}
                          style={{ marginLeft: "6.1%", marginTop: "8px" }}
                        >
                          <img
                            width="34"
                            height="12"
                            src="https://img.icons8.com/ios-glyphs/50/FFFFFF/plus-math.png"
                            alt="plus-math"
                          />
                        </button>
                      </div>
                      <button
                        className="add-to-cart"
                        onClick={() =>
                          handleAddToCart(
                            details,
                            product.startPrice[index],
                            index
                          )
                        }
                      >
                        Add
                      </button>
                      <p className="start-end-price">
                        £{product.startPrice[index]} each
                        <br />
                        Approx ${product.endPrice[index]}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="bquo">
                <table
                  className="sc-be0323db-0 exCVIm"
                  style={{ padding: "5%" }}
                >
                  <tbody style={{ padding: "5%" }}>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          paddingLeft: "6%",
                          paddingTop: "4%",
                          paddingBottom: "5%",
                        }}
                      >
                        <strong>Net Total</strong>
                        <br />
                        <small>Approx USD</small>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "6%",
                          paddingTop: "4%",
                          paddingBottom: "5%",
                        }}
                      >
                        <strong>£240.00</strong>
                        <br />
                        <small>$328.80</small>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          paddingBottom: "5%",
                        }}
                      >
                        Delivery from 14 days (8th Sep)
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="to-input">
                  <Link to="/">
                    <button>Continue to shop</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
