const productData = [
  {
    id: 1,
    name: "Blogger Outreach",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    description:
      "You have been Challenge! How long can you go without using kitchen rolls and disposable wipes? This is the ultimate solution to tackle this challenge. With this comprehensive set comprising of 4 General Purpose Cloths, 4 Glass & Polishing Cloths, 4 Wash & Wipe Cloths, a Cleaning Pad, and a Kitchen Whizz, you can take on the battle against waste with confidence. Embrace the power of reusable E-Cloths, meticulously designed with advanced fiber technology to make cleaning without single-use products a breeze. Say goodbye to the struggle of constantly restocking paper towels and wipes, and step into a sustainable cleaning routine that's both effective and eco-friendly. With this bundle, you can conquer the challenge of going green while maintaining a spotless and streak-free home, all while saving money. Join the movement and champion a cleaner, greener future today!",
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1001.jpg"],
  },
  {
    id: 2,
    name: "Niche Edits",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1002.jpg"],
  },
  {
    id: 3,
    name: "Multilingual Outreach",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1003.jpg"],
  },
  {
    id: 4,
    name: "PR Campaigns",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1004.jpg"],
  },
  {
    id: 5,
    name: "Media Placements DA 50+",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1005.jpg"],
  },
  {
    id: 6,
    name: "Press Release Distribution",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1006.jpg"],
  },
  {
    id: 7,
    name: "Local Citation Building",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1007.jpg"],
  },
  {
    id: 8,
    name: "Content Plan",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1008.jpg"],
  },
  {
    id: 9,
    name: "Keyword Research",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/1009.jpg"],
  },
  {
    id: 10,
    name: "Website Copywriting",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10010.jpg"],
  },
  {
    id: 11,
    name: "Blog Writing Service",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10011.jpg"],
  },
  {
    id: 12,
    name: "Press Release Writing Service",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10012.jpg"],
  },
  {
    id: 13,
    name: "Infographic Design",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10013.jpg"],
  },
  {
    id: 14,
    name: "Social Video Ads",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10014.jpg"],
  },
  {
    id: 15,
    name: "Explainer Videos",
    main: "Blogger Outreach Project Details",
    details: [
      "DA 10+ Websites *LowTier*",
      "DA 20+ Websites *MidTier*",
      "DA 30+ Websites *HighTier*",
      "DA 40+ Websites *Premium*",
      "DA 50+ Websites *Platinum*",
      "DA 10+ Websites (Payday Loans / Gambling / Pharma)",
    ],
    startPrice: ["60.00", "80.00", "100.00", "180.00", "280.00", "100.00"],
    endPrice: ["82.20", "109.60", "137.00", "246.60", "383.60", "137.00"],
    images: ["/fatjoe/10015.jpg"],
  },
];

export default productData;
